<template>
  <FHButton
    :href="`/video/${unit}/${content.activity_type.slug}`"
    defaultStyles
    class="c-activity-element"
    target="_blank"
    rel="noopener noreferrer"
  >
    <VideoIcon class="c-activity-element__icon" />
    {{ content.activity_type.name }}
  </FHButton>
</template>

<script>
import FHButton from '@forlagshuset/v-button'
import VideoIcon from '@/assets/svg/Icon_Video'

export default {
  components: {
    FHButton,
    VideoIcon,
  },

  props: {
    content: Object,
    unit: String,
  },
}
</script>

<style lang="scss">
.c-activity-element {
  display: block;
  background-color: transparent;
  border: none;
  padding: 0rem;
  line-height: 2;
}
.c-activity-element__icon {
  vertical-align: sub;
  height: 1.25rem;
}
</style>
