<template>
  <div :class="['c-unit', `c-unit--${appSlug}`]">
    <picture v-if="cover.coverSrc" class="c-unit__pic">
      <img class="c-unit__cover" :src="cover.coverSrc" :alt="cover.altText" />
    </picture>
    <VHeading v-if="cover.coverSrc" header="h1" class="c-unit__title u-px u-py">
      <span v-if="index" class="c-unit__title-index">{{ index }}</span>
      {{ title }}
    </VHeading>
    <FHBreadcrumbs
      v-if="crumbs"
      class="c-unit__breadcrumbs u-py u-px"
      :crumbs="crumbs"
    />
    <div class="o-wrapper o-wrapper--article u-px--3">
      <VHeading header="h2" class="c-unit__unit-title o-heading">
        {{ title }}
      </VHeading>
      <div class="o-description" v-md-html="description" />

      <div v-if="lessons.length" class="c-unit__lessons-section">
        <VHeading header="h3" class="o-heading">
          {{ $t('UNIT_LESSON_PLANS') }}
        </VHeading>
        <ul class="c-unit__lesson-plans">
          <li
            v-for="lesson in lessons"
            class="c-unit__lesson-plan"
            :key="lesson._id"
          >
            <router-link
              :to="{ name: 'Lesson', params: { lessonSlug: lesson.slug } }"
              class="c-unit__lesson-plan-link"
            >
              {{ lesson.name }}
            </router-link>
          </li>
        </ul>
      </div>

      <div v-if="lessons.length" class="c-unit__lessons-section">
        <VHeading header="h3" class="o-heading">
          {{ $t('UNIT_CONTENT') }}
        </VHeading>
        <ul class="c-unit__lesson-plans">
          <li
            v-for="item in content"
            class="c-unit__lesson-plan"
            :key="item._id"
          >
            <router-link
              :to="{
                name: 'Activity',
                params: {
                  unitSlug: appSlug,
                  activitySlug: item.video_type.slug,
                },
              }"
              class="c-unit__lesson-plan-link"
            >
              {{ lesson.name }}
            </router-link>
          </li>
        </ul>
      </div>

      <component
        v-for="section in content"
        :key="section.id"
        :is="componentNameNormalize(section.__component)"
        :content="section"
      />
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { addCloudinaryUrlParams, componentNameNormalize } from '@/utils'
import { fetchUnitBySlug, fetchCourseBySlug } from '@/services/strapi'
import FHBreadcrumbs from '@forlagshuset/v-breadcrumbs'
import VHeading from '@forlagshuset/v-heading'
import ComponentLearningElementTextBox from '@/components/LearningElements/TextBox'
import ComponentLearningElementTextElement from '@/components/LearningElements/TextElement'
import ComponentLearningElementArticleElement from '@/components/LearningElements/ArticleElement'
import ComponentLearningElementSectionTagElement from '@/components/LearningElements/SectionTagElement'
import ComponentLearningElementDbokLinkElement from '@/components/LearningElements/DbokLinkElement'
import ComponentLearningElementActivityElement from '@/components/LearningElements/ActivityElement'
import ComponentLearningElementVideoElement from '@/components/ComponentLearningElements/VideoElement'

export default {
  name: 'Unit',

  components: {
    VHeading,
    FHBreadcrumbs,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementArticleElement,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementDbokLinkElement,
    ComponentLearningElementActivityElement,
    ComponentLearningElementVideoElement,
  },

  setup(props, ctx) {
    const appSlug = computed(
      () => ctx.root.$options.router.history.current.params.unitSlug,
    )
    const index = ref(0)
    const title = ref('')
    const description = ref('')
    const lessons = ref([])
    const cover = ref({})
    const crumbs = ref([])
    const content = ref([])

    onMounted(async () => {
      //hide unit pages
      // const unit = await fetchUnitBySlug(appSlug.value)
      const unit = await fetchUnitBySlug('echo-welcome-to-echo')
      const units = await fetchCourseBySlug('echo')
      units.orderedUnits.forEach((unit, i) => {
        if (unit.unit.slug === appSlug.value) {
          index.value = i + 1

          return
        }
      })
      title.value = unit.name
      description.value = unit.description
      content.value = unit.content

      //get ordered lessons
      if (unit.orderedLessons.length) {
        unit.orderedLessons.forEach(lesson => {
          lessons.value.push(lesson.lesson)
        })
      } else {
        //fallback
        lessons.value = unit.lessons
      }

      if (unit.cover && unit.cover.image_type) {
        const coverURL = unit.cover.image_type.file.url
        const param = 'w_1024,c_scale/w_1024,h_114,c_crop' // Should be adjusted per project need (styling overview list for example)
        cover.value.coverSrc = addCloudinaryUrlParams(coverURL, param)
        cover.value.altText = unit.cover.altText
      }

      //add unit
      crumbs.value.push({
        type: 'unit',
        name: unit.name,
        slug: appSlug.value,
      })
    })

    return {
      appSlug,
      title,
      description,
      lessons,
      crumbs,
      cover,
      index,
      content,
      componentNameNormalize,
    }
  },
}
</script>

<style lang="scss">
.c-unit {
  min-height: calc(100vh - 12rem);
  padding-bottom: 5rem;
  @include bp-down($medium) {
    margin: 0 1rem;
  }
  &--echo-1-identity {
    .c-unit__title {
      background-color: $unit-identity-bg;
    }
  }
  &--echo-2-english-around-the-world {
    .c-unit__title {
      background-color: $unit-english-bg;
    }
  }
  &--echo-3-citizenship {
    .c-unit__title {
      background-color: $unit-citizenship-bg;
    }
  }
  &--echo-4-indigenous-peoples {
    .c-unit__title {
      background-color: $unit-indigenous-bg;
    }
  }
  &--echo-my-grammar {
    .c-unit__title {
      background-color: $unit-grammar-bg;
    }
  }
}
.c-unit__pic {
  width: 100%;
}
.c-unit__cover {
  margin: 0 auto;
  display: block;
}
.c-unit__title {
  font-family: $headers-font-family;
  color: $color-white;
  h1 {
    margin: 0;
  }
}
.c-unit__title-index {
  font-size: 1rem;
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
  border: 1px solid;
  vertical-align: middle;
  margin-top: 0.5rem;
}
.c-unit__breadcrumbs {
  @include bp-down($medium) {
    display: none;
  }
  .c-breadcrumbs__home {
    text-decoration: none;
    color: $color-black;
    .c-breadcrumbs__home {
      fill: none;
      width: 0;
    }
    &:before {
      content: 'Home';
      vertical-align: super;
    }
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}
.c-unit__lesson-plans {
  list-style: none none;
  padding: 0;
  li {
    &:before {
      content: ' - ';
    }
  }
}
</style>
